'use client';

import { useState } from 'react';
import { suscribeUser } from './action';
import { toast } from 'sonner';
import { useRef } from 'react';

function Newsletter() {
	const [email, setEmail] = useState('');
	const input = useRef<HTMLInputElement>(null);

	async function handleSubmit() {
		const response = await suscribeUser({ email });

		if (response?.error) {
			toast.error(response?.message);
		} else {
			toast.success('Gracias por suscribirte!');
			setEmail('');
			if (input.current) input.current.blur();
		}
	}
	return (
		<>
			<h2>Newsletter</h2>
			<input
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				id="newsletter-email"
				placeholder="Escribe tu correo"
				ref={input}
			/>
			<button onClick={handleSubmit}>Suscribirse</button>
		</>
	);
}

export default Newsletter;
