'use client';

import { Eye, User2, Settings2 } from 'lucide-react';
import { LogoutButton } from './logoutButton';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { useAdminNavContext } from '@/contexts/AdminNavContext';

interface Props {
	nombreLocal: string;
}
function AdminHeader({ nombreLocal }: Props) {
	const [currentActiveBtn, setCurrentActiveBtn] = useState(1);
	const { setIsPreviewSelected } = useAdminNavContext();
	return (
		<header className={'header'}>
			<div className={'box'}>
				<div className={'options'}>
					<LogoutButton />
				</div>
				<div className={'logoContainer'}>
					<Link href="/">
						<Image src="/logotipo.svg" width={240} height={48} alt="Logotipo de La Carta." priority />
					</Link>
					<div className={'separator'}></div>
					<p>{nombreLocal || 'Nombre del local'}</p>
				</div>
				<div className={'options'}>
					<button>
						<User2 size={22} />
					</button>
				</div>
			</div>
			<nav className={'nav'}>
				<button
					className={`${currentActiveBtn === 1 ? 'activeBtn' : 'not-active'}`}
					onClick={() => {
						setCurrentActiveBtn(1);
						setIsPreviewSelected(false);
					}}>
					<Settings2 size={22} />
				</button>
				<button
					className={`${currentActiveBtn === 2 ? 'activeBtn' : 'not-active'}`}
					onClick={() => {
						setCurrentActiveBtn(2);
						setIsPreviewSelected(true);
					}}>
					<Eye size={22} />
				</button>
			</nav>
		</header>
	);
}

export default AdminHeader;
