'use client';

import Image from 'next/image';
import styles from './footer.module.css';
import Link from 'next/link';
import { Facebook, Instagram, Linkedin, Twitter } from 'lucide-react';
import { useTransitionContext } from '@/contexts/TransitionContext';
import { MouseEvent } from 'react';
import { usePathname } from 'next/navigation';
import { Newsletter } from './newsletter';
import { useSession } from 'next-auth/react';

export const Footer = () => {
	const currentPath = usePathname();
	const { navigateTo, isAnimating } = useTransitionContext();
	const { data: session } = useSession();
	function handleClick(e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLImageElement>, href: string) {
		if (currentPath !== href && !isAnimating) {
			e.preventDefault();
			navigateTo(href);
		} else {
			e.preventDefault(); // Prevenir navegación si está animando o en la misma ruta
		}
	}
	return (
		<footer className={styles.footer}>
			<div className={styles.footerContentWrapper}>
				<div className={styles.firstBox}>
					<Link href={'/'} className={styles.logoContainer} aria-label="Ir al inicio">
						<Image src={'/logotipo.svg'} fill alt="Logotipo de La Carta." />
					</Link>
					<p>Crea y gestiona tu menú digital completo en cuestión de minutos.</p>
					<ul role="list">
						<li className={styles.socialMediaItem}>
							<Link
								className={styles.socialMediaLink}
								href={'https://www.facebook.com/lacartaa/'}
								aria-label="Enlace a Facebook"
								target="_blank"
								rel="noopener noreferrer">
								<Facebook />
							</Link>
						</li>
						<li className={styles.socialMediaItem}>
							<Link
								className={styles.socialMediaLink}
								href={'https://www.instagram.com/lacarta.com.ar/'}
								aria-label="Enlace a Instagram"
								target="_blank"
								rel="noopener noreferrer">
								<Instagram className={styles.socialMediaIcon} />
							</Link>
						</li>
						<li className={styles.socialMediaItem}>
							<Link
								className={styles.socialMediaLink}
								href={'https://x.com/lacartaoficial'}
								aria-label="Enlace a Twitter"
								target="_blank"
								rel="noopener noreferrer">
								<Twitter className={styles.socialMediaIcon} />
							</Link>
						</li>
						<li className={styles.socialMediaItem}>
							<Link
								className={styles.socialMediaLink}
								href={'/'}
								aria-label="Enlace a LinkedIn"
								target="_blank"
								rel="noopener noreferrer">
								<Linkedin className={styles.socialMediaIcon} />
							</Link>
						</li>
					</ul>
				</div>
				<div className={styles.footerItem}>
					<h2>Enlaces</h2>
					<ul role="list">
						<li className={styles.footerNavSectionListItem}>
							<Link href={'/producto'} onClick={(e) => handleClick(e, '/producto')}>
								Producto
							</Link>
						</li>
						<li className={styles.footerNavSectionListItem}>
							<Link href={'/clientes'} onClick={(e) => handleClick(e, '/clientes')}>
								Clientes
							</Link>
						</li>
						<li className={styles.footerNavSectionListItem}>
							<Link href={'/blog'} onClick={(e) => handleClick(e, '/Contacto')}>
								Contacto
							</Link>
						</li>
					</ul>
				</div>
				<div className={styles.footerItem}>
					<h2>Otros</h2>
					<ul role="list">
						<li className={styles.footerNavSectionListItem}>
							<Link href={'/terminos-y-condiciones'} onClick={(e) => handleClick(e, '/terminos-y-condiciones')}>
								Términos & Condiciones
							</Link>
						</li>
						<li className={styles.footerNavSectionListItem}>
							{session?.user?.id ? (
								<Link href={'/micarta'}>Accede al panel</Link>
							) : (
								<Link href={'/contacto'} onClick={(e) => handleClick(e, '/contacto')}>
									Comienza ahora
								</Link>
							)}
						</li>
						<li className={styles.footerNavSectionListItem}>
							<Link href={'/blog'} onClick={(e) => handleClick(e, '/blog')}>
								Publicaciones
							</Link>
						</li>
					</ul>
				</div>
				<div className={`${styles.footerItem} ${styles.footerNewsLetter}`}>
					<Newsletter />
				</div>
			</div>
			<p className={styles.footerCopyright}>
				&copy; Sitio hecho por{' '}
				<Link
					href={'https://lusol.dev'}
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Visitar el portfolio de lusoldev">
					lusoldev
				</Link>
			</p>
		</footer>
	);
};
