// TransitionContext.tsx
'use client';

import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useRouter, usePathname } from 'next/navigation';

interface TransitionContextType {
	isAnimating: boolean;
	navigateTo: (href: string) => void;
	endTransition: () => void;
	pageName: string;
	isUpToAnimate: boolean;
}

const TransitionContext = createContext<TransitionContextType | undefined>(undefined);

const PAGE_NAMES: { [key: string]: string } = {
	'/': 'Inicio',
	'/producto': 'Producto',
	'/clientes': 'Clientes',
	'/blog': 'Blog',
	'/contacto': 'Contacto',
	'/iniciar-sesion': 'Iniciar sesión',
	'/terminos-y-condiciones': 'Condiciones',
	'/politica-de-privacidad': 'Privacidad',
	'/registro': 'Registro'
};

export const TransitionProvider = ({ children }: { children: ReactNode }) => {
	const [isAnimating, setIsAnimating] = useState(false);
	const [isUpToAnimate, setIsUpToAnimate] = useState(true);
	const [pageName, setPageName] = useState('');
	const router = useRouter();
	const pathname = usePathname();

	useEffect(() => {
		// Mapear rutas a nombres de páginas
		const pageNames: { [key: string]: string } = PAGE_NAMES;
		setPageName(pageNames[pathname] || '');
	}, [pathname]);

	const navigateTo = (href: string) => {
		if (isAnimating) return; // Evitar navegación si ya está animando
		setIsAnimating(true);
		setIsUpToAnimate(false);

		// Establecer el nombre de la página de destino
		const pageNames: { [key: string]: string } = PAGE_NAMES;
		setPageName(pageNames[href] || 'Home');

		// Programar la navegación después de la mitad de la duración de la animación
		const totalDuration = 1000; // Duración total de la animación en milisegundos
		const halfway = totalDuration * 0.5; // Punto medio de la animación

		setTimeout(() => {
			router.push(href);
		}, halfway);
	};

	const endTransition = () => {
		setIsAnimating(false);
		setTimeout(() => {
			setIsUpToAnimate(true);
		}, 1000);
	};

	return (
		<TransitionContext.Provider value={{ isAnimating, navigateTo, endTransition, pageName, isUpToAnimate }}>
			{children}
		</TransitionContext.Provider>
	);
};

export const useTransitionContext = () => {
	const context = useContext(TransitionContext);
	if (!context) {
		throw new Error('useTransitionContext must be used within a TransitionProvider');
	}
	return context;
};
