// components/Header.tsx

'use client';

import Image from 'next/image';
import Link from 'next/link';
import styles from './header.module.css';
import { usePathname } from 'next/navigation';
import { useState, useRef, useEffect, MouseEvent } from 'react';
import { useTransitionContext } from '@/contexts/TransitionContext';
import { useSession } from 'next-auth/react';

export const Header: React.FC = () => {
	const currentPath = usePathname();
	const [navMobileOpen, setNavMobileOpen] = useState(false);
	const firstNavLinkRef = useRef<HTMLAnchorElement>(null);
	const lastNavLinkRef = useRef<HTMLAnchorElement>(null);
	const toggleButtonRef = useRef<HTMLButtonElement>(null);
	const { navigateTo, isAnimating } = useTransitionContext();
	const { data: session } = useSession();
	function handleToggle() {
		setNavMobileOpen((prev) => !prev);
	}

	function handleClick(e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLImageElement>, href: string) {
		if (currentPath !== href && !isAnimating) {
			e.preventDefault();
			setNavMobileOpen(false);
			navigateTo(href);
		} else {
			e.preventDefault(); // Prevenir navegación si está animando o en la misma ruta
		}
	}

	// Mover el enfoque al primer enlace cuando el menú se abre
	useEffect(() => {
		if (navMobileOpen && firstNavLinkRef.current) {
			firstNavLinkRef.current.focus();
		}
	}, [navMobileOpen]);

	// Manejar el cierre del menú al tabular después del último enlace
	useEffect(() => {
		const handleKeyDown = (event: globalThis.KeyboardEvent) => {
			if (!navMobileOpen) return;

			if (event.key === 'Escape') {
				setNavMobileOpen(false);
				toggleButtonRef.current?.focus();
			}

			// Trampa de enfoque para mantener el enfoque dentro del menú abierto
			if (event.key === 'Tab') {
				if (document.activeElement === lastNavLinkRef.current && !event.shiftKey) {
					event.preventDefault();
					setNavMobileOpen(false);
					toggleButtonRef.current?.focus();
				}

				if (document.activeElement === toggleButtonRef.current && event.shiftKey) {
					event.preventDefault();
					lastNavLinkRef.current?.focus();
				}
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [navMobileOpen]);

	return (
		<header className={styles.header}>
			<div className={styles.headerWrapper}>
				<div className={styles.headerContentWrapper}>
					<Link href="/" className={styles.logoContainer} onClick={(e) => handleClick(e, '/')}>
						<Image src="/logotipo.svg" fill alt="Logotipo de La Carta." priority />
					</Link>
					<button
						ref={toggleButtonRef}
						className={styles.navToggleButton}
						onClick={handleToggle}
						aria-label={navMobileOpen ? 'Cerrar menú de navegación' : 'Abrir menú de navegación'}
						aria-expanded={navMobileOpen}
						aria-disabled={isAnimating} // Añadir atributo para accesibilidad
					>
						<div className={`${styles.MobileNavIcon} ${navMobileOpen ? styles.MobileNavIconOpen : ''}`}></div>
					</button>
				</div>
				<nav
					id="primary-navigation"
					className={`${styles.headerNavigation} ${navMobileOpen ? styles.headerNavigationOpen : ''}`}
					aria-label="Menú principal">
					<ul className={styles.headerNavigationList}>
						<li className={styles.navListItem}>
							<Link
								href="/"
								onClick={(e) => handleClick(e, '/')}
								className={`${styles.headerNavigationLink} ${currentPath === '/' ? styles.navLinkActive : ''}`}
								ref={firstNavLinkRef}
								aria-disabled={isAnimating}>
								Inicio
							</Link>
						</li>
						<li className={styles.navListItem}>
							<Link
								href="/producto"
								onClick={(e) => handleClick(e, '/producto')}
								className={`${styles.headerNavigationLink} ${currentPath === '/producto' ? styles.navLinkActive : ''}`}
								aria-disabled={isAnimating}>
								Producto
							</Link>
						</li>
						<li className={styles.navListItem}>
							<Link
								href="/clientes"
								onClick={(e) => handleClick(e, '/clientes')}
								className={`${styles.headerNavigationLink} ${currentPath === '/clientes' ? styles.navLinkActive : ''}`}
								aria-disabled={isAnimating}>
								Clientes
							</Link>
						</li>
						<li className={styles.navListItem}>
							<Link
								href="/contacto"
								onClick={(e) => handleClick(e, '/contacto')}
								className={`${styles.headerNavigationLink} ${currentPath === '/contacto' ? styles.navLinkActive : ''}`}
								aria-disabled={isAnimating}>
								Contacto
							</Link>
						</li>
						{session?.user ? (
							<li className={styles.navListItem}>
								<Link
									href="/micarta"
									className={styles.headerNavigationLink}
									ref={lastNavLinkRef}
									aria-disabled={isAnimating}>
									Accede al panel
								</Link>
							</li>
						) : currentPath === '/iniciar-sesion' ? (
							<li className={styles.navListItem}>
								<Link
									href="/registro"
									onClick={(e) => handleClick(e, '/contacto')}
									className={styles.headerNavigationLink}
									ref={lastNavLinkRef}
									aria-disabled={isAnimating}>
									Crea tu carta
								</Link>
							</li>
						) : (
							<li className={styles.navListItem}>
								<Link
									href="/iniciar-sesion"
									onClick={(e) => handleClick(e, '/iniciar-sesion')}
									className={styles.headerNavigationLink}
									ref={lastNavLinkRef}
									aria-disabled={isAnimating}>
									Iniciar sesión
								</Link>
							</li>
						)}
					</ul>
				</nav>
			</div>
		</header>
	);
};

export default Header;
