'use client';

import { createContext, useContext, useState } from 'react';

interface AdminNavContextType {
	isPreviewSelected: boolean;
	setIsPreviewSelected: (value: boolean) => void;
}

const AdminNavContext = createContext<AdminNavContextType | undefined>(undefined);

export const AdminNavContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [isPreviewSelected, setIsPreviewSelected] = useState(false);

	return (
		<AdminNavContext.Provider
			value={{
				isPreviewSelected,
				setIsPreviewSelected
			}}>
			{children}
		</AdminNavContext.Provider>
	);
};

export const useAdminNavContext = () => {
	const context = useContext(AdminNavContext);

	if (!context) {
		throw new Error('useAdminNavContext must be used within a AdminNavContextProvider');
	}
	return context;
};
