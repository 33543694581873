'use client';

import { LogOut } from 'lucide-react';
import { signOut } from 'next-auth/react';

function LogoutButton() {
	return (
		<button onClick={() => signOut()}>
			<LogOut size={22} />
		</button>
	);
}

export default LogoutButton;
